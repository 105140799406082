const routes = [
  {
    path: ["/", "/home", "/yt/*", "/youtube/*", "/face/*", "/facebook/*", "/insta/*", "/instagram/*","/linkedin/*","/blog/*","/site/*"],
    exact: true,
    component: "Home",
    root_path: "pages",
  },
  {
    path: ["/thankyou"],
    exact: true,
    component: "ThankYou",
    root_path: "pages",
  },
  {
    path: ["/app-exact"],
    exact: true,
    component: "AppExact",
    root_path: "pages",
  },
  {
    path: ["/create"],
    exact: true,
    component: "create",
    root_path: "pages",
  },
  {
    path: ["/alredycreated"],
    exact: true,
    component: "alredycreated",
    root_path: "pages",
  },
  {
    path: ["/mobile","/v2/mobile"],
    exact: true,
    component: "MobileApp",
    root_path: "pages",
  },
  // disabled
  // {
  //   path: ["/link","/links"],
  //   exact: true,
  //   component: "LinkGenerator",
  //   root_path: "pages",
  // },  
  {
    path: ["/v2","/v2/Mobile/create"],
    exact: true,
    component: "Home",
    root_path: "pages",
  },
  {
    path: ["/v2/create"],
    exact: true,
    component: "Create",
    root_path: "pages",
  },
  {
    path: ["/v2/napratica","/napratica"],
    exact: true,
    component: "napratica",
    root_path: "pages",
  },
  {
    path: ["*"],
    exact: false,
    component: "Home",
    root_path: "pages",
  },
]

export default routes
